import React from 'react';
import Layout from '../components/layout';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PageHeading from '../components/page-heading';

const Grid = styled.section`
	display: grid;
	gap: 20px;
	@media (min-width: 768px) {
		gap: 80px;
		grid-template-areas: 'blurb blurb .' 'skills clients .';
	}
`;

const Experience = styled.article`
	@media (min-width: 768px) {
		grid-area: skills;
	}
	h4 {
		&:not(:last-child) {
			margin-bottom: 24px;
		}
		&:not(:first-child) {
			margin-top: 40px;
		}
	}
	p {
		margin-bottom: 0;
	}
	ul {
		&:not(:last-child) {
			margin-bottom: 24px;
		}
	}
`;

const Description = styled.article`
	@media (min-width: 768px) {
		grid-area: blurb;
	}
`;

const Clients = styled.div`
	@media (min-width: 768px) {
		grid-area: clients;
		align-self: end;
	}
`;

const About = ({ data }) => {
	const about = data.allContentfulAbout.nodes;
	const clients = about[0].clients;
	return (
		<Layout>
			<PageHeading text={'About'} />
			<Grid>
				<Experience
					dangerouslySetInnerHTML={{
						__html: about[0].skillsAndExperience?.childMarkdownRemark.html
					}}
				/>

				<Description
					dangerouslySetInnerHTML={{
						__html: about[0].body?.childMarkdownRemark.html
					}}
				/>

				{clients && (
					<Clients>
						<h4>Clients</h4>
						<br />
						<ul>
							{clients.map(client => (
								<li>{client}</li>
							))}
						</ul>
					</Clients>
				)}
			</Grid>
		</Layout>
	);
};

export default About;

export const pageQuery = graphql`
	query AboutQuery {
		allContentfulAbout {
			nodes {
				body {
					childMarkdownRemark {
						html
					}
				}
				skillsAndExperience {
					childMarkdownRemark {
						html
					}
				}
				clients
			}
		}
	}
`;
